import ajax from '@/utils/ajax.js'

/**
 * 获取区域售价列表
 * @param {*} params 
 * @returns 
 */
export function getAreaList(params) {
  return ajax.post('/RegionalPricing/getList',params)
}

/**
 * 获取商品SKU区域售价
 * @param {*} params 
 * @returns 
 */
 export function getAreaSkuPirce(params) {
  return ajax.post('/RegionalPricing/getListForGoodsSku',params)
}


/**
 * 新增区域售价
 * @param {*} params 
 * @returns 
 */
 export function addAreaTemplate(params) {
  return ajax.post('/RegionalPricing/add',params)
}
/**
 * 编辑区域售价
 * @param {*} params 
 * @returns 
 */
 export function editAreaTemplate(params) {
  return ajax.post('/RegionalPricing/update',params)
}
