<template>
  <div class="h-full">
    <base-table class="h-full" :columnsData="columns" rowKey="id" :tableData="areaList">
    
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">售价区域模板</h4>
          <a-button type="primary" @click="handlerAdd" html-type="submit">新增</a-button>
        </div>
      </template>

      <template #operation="{record}">
        <a-button type="link" @click="handlerEdit(record)">修改</a-button>
        <!-- <a-button type="link"
                  v-if="isEdit"
        @click="handlerDelete(index)">删除</a-button>-->
      </template>

    </base-table>
    <a-modal v-model="visibleModal" :title="areaTitle" @ok="handleSave">
      <a-form-model-item
        class="item-class"
        :labelCol="{ span: 2 }"
        :wrapperCol=" { span: 20 }"
        label="名称"
      >
        <a-input style="width:250px;" v-model="areaName" placeholder="请输入名称" />
      </a-form-model-item>
    </a-modal>
  </div>
</template>

<script>
import {
  getAreaList,
  addAreaTemplate,
  editAreaTemplate,
} from "@/api/area-price.js"

export default {
  data() {
    return {
      columns: [
        {
          title: "售价区域模板名称",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 200,
          slots: { customRender: "operation" },
        },
      ],
      areaList: [],
      areaName: "",
      areaId: "",
      areaTitle: "新建售价区域模板",
      type: "add",
      visibleModal: false,
    }
  },
  async mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      const { data, code } = await getAreaList()
      if (code == 0) {
        this.areaList = data.list.reverse()
      }
    },
    handlerAdd() {
      this.type = "add"
      this.areaName = ""
      this.visibleModal = true
      this.areaTitle = "新建售价区域模板"
    },
    handlerEdit(args) {
      this.type = "edit"
      this.areaName = args.name
      this.areaId = args.id
      this.visibleModal = true
      this.areaTitle = "编辑售价区域模板"
    },
    handleSave() {
      if (this.areaName == "") {
        this.$message.error("请输入名称再保存")
        return
      }
      if (this.type == "edit") {
        editAreaTemplate({ name: this.areaName, id: this.areaId }).then(
          (res) => {
            if (res.code === 0) {
              this.$message.success("编辑区域名称成功！")
              this.visibleModal = false
              this.initData()
            }
          }
        )
      } else {
        addAreaTemplate({ name: this.areaName }).then((res) => {
          if (res.code === 0) {
            this.$message.success("新增区域名称成功！")
            this.visibleModal = false
            this.initData()
          }
        })
      }
    },
  },
}
</script>


<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 0;
}
</style>
